import styled from "styled-components";

export const TextContainer = styled.p`
	font-size: 20px;
	line-height: 24px;
	/* margin: 0 0 24px; */
    line-break: auto;
    word-spacing: 4px;
    line-height: 40px;
    padding: 60px;
    background-color: black;

    @media screen and (max-width: 765px) {
        font-size: 15px;
        padding: 30px;
        word-spacing: 2px;
    }

    @media screen and (max-width: 400px) {
        font-size: 12px;
        padding: 10px;
        word-spacing: 1px;
    }
`

export const BodyText = styled.div`
    margin: 0;
    background-color: black;
    height: min-content;
    font-family: 'Roboto Condensed', sans-serif;
`

export const Box = styled.p`
    background-color: white;
    color: #101010;
    padding: 30px;
`

export const Iframe = styled.iframe`
    margin-left: auto;
    margin-right: auto;
    display: ${({iframedisplay}) => (iframedisplay ? 'flex' : 'none')};
    /* padding: 20px;
    background-color: aliceblue; */
    /* height: 100vh;
    width: 80vw; */
    width: 80vw;
    height: 90vh;
    margin-top: -10%;

    @media screen and (max-width: 480px) {
        margin-top: -50%;
        height: 500px;
        width: auto;
    } 
`

export const Img = styled.img`
    margin-left: auto;
    margin-right: auto; 
    height: auto;
    width: 80vw;
    display: ${({imgDisplay}) => imgDisplay ? 'flex' : 'none'};
`

export const Img2 = styled.img`
    
    margin-left: auto;
    margin-right: auto; 
    height: auto;
    width: 80vw;
    display: ${({imgDisplay}) => imgDisplay ? 'flex' : 'none'};
    margin-top: ${({topMargin2}) => topMargin2 ? '-20%' : '0'};

    @media screen and (max-width: 480px) {
        margin-top: ${({topMargin}) => topMargin ? '-50%' : '0'};
        height: auto;
        width: 80vw;
    } 
`