import React from 'react'
import { Button } from '../ButtonElement';
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, ImgWrap, Img, Iframe } from './InfoElements';
import { animateScroll as scroll } from 'react-scroll';

const InfoSection = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt, primary, dark, dark2, scrollId, scrollId2, appear, iframeDisplay, imgDisplay, srcLink, appear2, buttonLabel2, appear3 }) => {
    
    const toggleHome = () => {
        scroll.scrollToTop();
    };
    
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle>
                                <BtnWrap>
                                    <Button to={scrollId} onClick={toggleHome} smooth={true} duration={200} spy={true} exact="true" offset={-80} primary={primary ? 1 : 0} dark={dark ? 1 : 0} dark2={dark2 ? 1 : 0} appear={appear}>{buttonLabel}</Button>
                                    <Button to={scrollId2} onClick={ toggleHome } smooth={true} duration={200} spy={true} exact="true" offset={-80} primary={primary ? 1 : 0} dark={dark ? 1 : 0} dark2={dark2 ? 0 : 1} appear={appear2}>{buttonLabel2}</Button>
                                    <Button to={scrollId2} onClick={ () => window.location = 'mailto: tesini22@gmail.com' } smooth={true} duration={200} spy={true} exact="true" offset={-80} primary={primary ? 1 : 0} dark={dark ? 1 : 0} dark2={dark2 ? 0 : 1} appear={appear3}>Professional Contact</Button>
                                </BtnWrap>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} imgDisplay={imgDisplay}/>
                                <Iframe src={srcLink} width="640" height="1027" frameborder="0" marginheight="0" marginwidth="0" iframeDisplay={iframeDisplay}></Iframe>
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection;
