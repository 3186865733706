import './App.css';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import BlogPost from './pages/signin';
import computational from './pages/computational';
import TermsOfService from './pages/termsofservice';
import PrivacyPolicy from './pages/privacypolicy';
import ContactUs from './pages/contactus';
import Sizing_guidelines from './pages/sizing_guidelines';
import Chat from './pages/colab_lic';
import Aboutus from './pages/aboutus';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/blogpost" component={BlogPost} exact />
        <Route path="/computational" component={computational} exact />
        <Route path="/terms-of-service" component={TermsOfService} exact />
        <Route path="/privacy-policy" component={PrivacyPolicy} exact />
        <Route path="/sizing_guidelines" component={Sizing_guidelines} exact />
        <Route path="/contact-us" component={ContactUs} exact />
        <Route path="/chat-with-us" component={Chat} exact />
        <Route path="/about-pacified" component={Aboutus} exact />
      </Switch>
    </Router>
  );
}

export default App;
