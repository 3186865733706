import React, { useState } from 'react';
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import Blog from '../components/Blog';
import InfoSection from "../components/InfoSection";
import { homeObjFour } from "../components/InfoSection/Data";

const BlogPost = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} type={false}/>
            <Navbar toggle={toggle} margin={true} type={false}/>
            <InfoSection {...homeObjFour}/>
            <Blog/>
            <Footer/>
        </>
    )
}

export default BlogPost;
