import React from 'react';
// FaFacebook, FaLinkedin, FaTwitter
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, SocialMedia, SocialMediaWrap, SocialLogo, SocialIcons, SocialIconLink, WebsiteRights, Imgs } from './FooterElements';
import { animateScroll as scroll } from 'react-scroll';
import img from '../../images/PacifiedLogo_White.png';

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>About Us</FooterLinkTitle>
                                <FooterLink onClick={toggleHome} to="/about-pacified">About Pacified®</FooterLink>
                                {/* <FooterLink onClick={toggleHome} to="/terms-of-service">Terms of Service</FooterLink> */}
                                <FooterLink onClick={toggleHome} to="/privacy-policy">Privacy Policy</FooterLink>
                                <FooterLink onClick={toggleHome} to="/sizing_guidelines">Sizing Guidelines</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Pacified</FooterLinkTitle>
                                <FooterLink to={{pathname:"https://apps.apple.com/us/app/pacified/id1528971525"}} target="_blank">App Store</FooterLink>
                                <FooterLink to={{pathname:"https://play.google.com/store/apps/details?id=info.toothprint"}} target="_blank">Google Play</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Contact</FooterLinkTitle>
                                <FooterLink onClick={toggleHome} to="/contact-us">Chat with Us</FooterLink>
                                <FooterLink onClick={toggleHome} to="/contact-us">Business Enquiry</FooterLink>
                                <FooterLink onClick={toggleHome} to="/chat-with-us">Partnership</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                                <FooterLink to={{pathname:"https://www.instagram.com/pacifiedapp/"}} target="_blank">Instagram</FooterLink>
                                <FooterLink to={{pathname:"https://www.youtube.com/channel/UCloOzkoCNr8hQbtbJoW1K2g"}} target="_blank">YouTube</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to="/" onClick={toggleHome}>
                            <Imgs src={img}></Imgs>
                        </SocialLogo>
                        <WebsiteRights>Toothprints © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="https://www.instagram.com/pacifiedapp/" target="_blank" aria-label="Instagram">
                                <FaInstagram/>
                            </SocialIconLink>
                            <SocialIconLink href="https://www.youtube.com/channel/UCloOzkoCNr8hQbtbJoW1K2g" target="_blank" aria-label="YouTube">
                                <FaYoutube/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
};

export default Footer;
