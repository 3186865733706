import React from 'react';
import InfoSection from '../InfoSection';
import { homeObjNine } from '../InfoSection/Data';
import { BodyText, TextContainer, Box, BoxTitle } from './BlogElements';
// Use Linked for hyper linking | check BlogElements.js

const Blog = () => {
    return (
            <BodyText id="post">
                <TextContainer>
                    <Box>
                    <BoxTitle>Babies acceptance of pacifiers is now thought to be related to both the size and design</BoxTitle>

                    Babies are not <i>one size fits all</i>, but some marketing ads say “acceptance guaranteed”. Can you imagine how comfortable a newborn 10 lb. 11 oz baby would be if mom or dad tried to squeeze them into a newborn <i>sized</i> onesie?...ouch!  It seems almost nonsensical just thinking about it, but chronological age is the approach that companies CURRENTLY use for <i>sizing</i> baby pacifiers.<br></br><br></br>

                    When purchasing pacifiers, parents are offered a wide variety of options, including orthodontic versions, which refer to the specific shape of the bulb (portion that goes in mouth) By calling it “orthodontic” it confusingly seems to profess that it aligns the jaw of the baby properly so as not to interfere with oral development. Baby product companies <b>do not standardize</b> their bulb sizes or “stage” them based on the  millimeter width of the bulb. Their recommendations often have no scientific correlations with the baby’s chronological age and size of the palate. Essentially, the size and shape of the bulb of pacifiers vary from brand to brand, and they are labeled a certain chronological age, but without cross brand consistency. A pacifier labeled 6 to 12 months for one brand may be a different physical size than that from another brand labeled for the same age. With this in mind, buying the proper sized pacifier <i>will</i> support the palate and limit orthodontic issues for your child later in life. It has as much to do with the physical size of the pacifier bulb fitting properly into the  width of the palate, rather than the design of the pacifier itself.<br></br><br></br>

                    Fortunately for parents who are now entering panic mode as they read this article, there is already a solution to your pacifier problem! Available on the App Store and Google Play, the <i>Pacified App®</i> is a revolutionary platform which offers a simple, yet intuitive way to find the right pacifier for your baby, based on facial biometrics and other infant physical parameters.<br></br><br></br>
                    The scientifically-proven algorithm will scan a photograph of the baby’s face, make the mathematical calculations and then recommend the best scientifically designed and properly sized pacifier from those currently available in the market. The bulbs of major pacifier brands have been measured, and the app recommends specific pacifiers based on the size of your baby, and the actual size of the pacifier.<br></br><br></br>
                    The app has been developed with the supervision of experienced pediatric dentists and orthodontists in the country. Using this technology, your trip to the store (or online purchase) will be less confusing. and your baby’s growth and development will be considered.<br></br><br></br>
                    Many parents are now saying ..<i>”if I had only known”</i>... use <b>Pacified®</b>, the app that helps you find the <i>right sized pacifier</i> for your baby.<br></br><br></br>
                    </Box>
                </TextContainer>
                <InfoSection {...homeObjNine}/>
                <TextContainer>
                    <Box>
                    <BoxTitle>A Professional Perspective</BoxTitle>
                    Toothprints, PC has published the smartphone application (Pacified®) to help mothers choose the <b>proper size pacifier for their baby</b>. The proper size pacifier provides the palatal support afforded babies during breastfeeding. Chronological age sizing by the baby company brands is not evidenced based, is often dependent on marketing and retail SKUs and it is <b>not standardized across brands</b>. It is <u>not</u> related to the baby’s individual biometrics, ethnicity, weight or sex. This application will allow the companies to get back to the science and help them to appreciate that palatal support and free mandibular movement during NNS are important for infant oral health. <b>Malocclusions related to persistent pacifier use are common</b> (Dogramaci et al 2016)¹ (Warren et al 2002)² <b>and these malocclusions effect the entire Cranio-Facial-Respiratory Complex CFRC</b> (Boyd 2019)³.  Simply, a too small pacifier bulb lacks of proper palatal contact (Lee et al 2021)⁴ and contributes to transverse palatal collapse of the maxillary arch. Likewise, a restrictive pacifier shield (inward pull against face during sucking of bulb) can result in restrictive mandibular growth and poor airway development.<br></br><br></br>

                    To use the <i>Pacified®</i> app: A mother simply enters demographic data (age, sex, weight, ethnicity), takes a facial photo of baby/toddler and with facial recognition technology and the app scans and quantifies the anthropometric measurements. An algorithm, using known correlations in the literature (references), is then predictive and recommends a properly sized pacifier (pacifier bulbs vary from 13 mm to 25 mm without standardization of sizing across brands). The entire process of using the app takes less than 2 minutes. It has completed the validation/calibration phase and is available for trial. The <i>Pacified®</i> fitting system is patented.<br></br><br></br>

                    Parallel to <i>Pacified®</i> we are developing a <i>Gnathic</i> app. This smartphone application is for professionals to <b>evaluate soft tissue biometrics as diagnostic of micrognathic growers</b>. Based on three mandibular analyses, the type used to diagnose Apparent Life-Threatening Events (ALTE) or syndromic babies, the app will be able to quantify mandibular size and posture. This is particularly important in those pacifier babies using pacifiers with <b>shields that are restricting free forward mandibular movement</b>. The relationship of mandibular size, mandibular posture and mandibular volume do affect airway health (Horn et al 2006)⁵, TMJ development from birth to age seven is dependent on the functional physiology of the stomatognathic system (Wright and Moffet 1974)⁶.<br></br><br></br><br></br>
                    <ol>
                    <li>
                        Doğramacı, E. J., & Rossi-Fedele, G. (2016). Establishing the association between nonnutritive sucking behavior and malocclusions: A systematic review and meta-analysis. The Journal of the American Dental Association, 147(12), 926-934.
                    </li>
                    <li>
                    Schmid, K. M., Kugler, R., Nalabothu, P., Bosch, C., & Verna, C. (2018). The effect of pacifier sucking on orofacial structures: a systematic literature review. Progress in orthodontics, 19(1), 8.
                    </li>
                    <li>
                    Levrini, L. Merlo, P. Paracchini, L.(2007) Different geometric patterns of pacifiers compared on the basis of finite element analysis.. European Journal of Paediatric Dentistry. 8(4):173-8
                    </li>
                    <li>
                    Lee, CL, Costello, M, Tesini, DA. Computational simulation of pacifier deformation and interaction with the palate. Clin Exp Dent Res. 2021; 1-4 
                    https://doi.org/10.1002/cre2.428
                    </li>
                    <li>
                    Horn, M. H., Kinnamon, D. D., Ferraro, N., & Curley, M. A. (2006). Smaller mandibular size in infants with a history of an apparent life-threatening event. The Journal of pediatrics, 149(4), 499-504.
                    </li>
                    <li>
                    Wright, D. M., & Moffett Jr, B. C. (1974). The postnatal development of the human temporomandibular joint. American Journal of Anatomy, 141(2), 235-249.
                    </li>
                    </ol>
                    </Box>
                </TextContainer>
            </BodyText>
    )
}

export default Blog;
