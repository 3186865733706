import styled from "styled-components";
import { Link } from "react-router-dom";

export const TextContainer = styled.p`
	font-size: 20px;
	line-height: 24px;
	/* margin: 0 0 24px; */
    line-break: auto;
    word-spacing: 4px;
    line-height: 40px;
    padding: 60px;
    background-color: black;

    @media screen and (max-width: 765px) {
        font-size: 15px;
        padding: 30px;
        word-spacing: 2px;
    }

    @media screen and (max-width: 400px) {
        font-size: 12px;
        padding: 10px;
        word-spacing: 1px;
    }
`

export const BodyText = styled.div`
    margin: 0;
    background-color: black;
    height: min-content;
    font-family: 'Roboto Condensed', sans-serif;
`

export const Box = styled.p`
    background-color: white;
    color: #101010;
    padding: 30px;
`

export const Linked = styled(Link)`
    text-decoration: none;
    color: blueviolet;
`

export const BoxTitle = styled.h3`
    text-align: center;
    padding: 20px;
    margin-bottom: 10px;
`