import React, {useState} from 'react';
import Video from '../../videos/Background.mp4';
import image from '../../images/PacifiedLogo_White.png';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, Img, Button } from './HeroElements';
import ModalVideo from 'react-modal-video';
import '../scss/modal-video.scss';
// import ReactPlayer from 'react-player';

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    const [isOpen, setOpen] = useState(false)

    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoBg autoPlay loop muted playsinline src={Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Oral healthcare redefined</HeroH1>
                <HeroP>Download <Img src={image} alt='logo'></Img> to choose the right pacifier for your baby.</HeroP>
                <HeroBtnWrapper>
                    <React.Fragment>
			            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="KW-QH6cy6wU" onClose={() => setOpen(false)} />
                    </React.Fragment>
                    <Button to="download" onClick={()=> setOpen(true)} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>Watch explainer video{hover ? <ArrowForward /> : <ArrowRight />}</Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;


