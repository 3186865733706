import React from 'react';
import styled from 'styled-components';
import Pdf from '../pdfs/computational.pdf';

const computational = () => {
    return (
        <Embed src={Pdf}></Embed>
    )
};

export default computational;

const Embed = styled.embed`
    height: 100vh;
    width: 100vw;
`