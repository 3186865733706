export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Scientifically Accurate',
    headline: 'Choose Right. Always.',
    description: 'With our unique algorithm, we are sure to provide the right pacifier for your baby.',
    buttonLabel: 'Learn more about Pacified®',
    buttonLabel2: 'Read about Sizing Guidelines',
    imgStart: false,
    scrollId: '/about-pacified',
    scrollId2: '/sizing_guidelines',
    img: require('../../images/svg-2.svg').default,
    alt: 'Pacifier',
    dark: true,
    primary: true,
    darkText: false,
    appear: true,
    appear2: true,
    iframeDisplay: false,
    imgDisplay: true
} 

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Worth the read',
    headline: 'If it Fits right, it Soothes right.',
    description: 'A blog for parents and professionals.',
    buttonLabel: 'Read',
    imgStart: true,
    scrollId: '/blogpost',
    img: require('../../images/svg-4.svg').default,
    alt: 'Pacifier',
    dark: false,
    primary: false,
    darkText: true,
    appear: true,
    appear2: false,
    iframeDisplay: false,
    imgDisplay: true
} 

export const homeObjThree = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine: 'We care.',
    headline: 'Pacified App® is for you.',
    description: 'Ask us how we can help you better.',
    buttonLabel: 'Contact Us',
    imgStart: false,
    scrollId: '/contact-us',
    img: require('../../images/svg-5.svg').default,
    alt: 'Pacifier',
    dark: false,
    primary: false,
    darkText: true,
    appear: true,
    appear2: false,
    iframeDisplay: false,
    imgDisplay: true,
    buttonLabel2: 'Professional Contact',
    appear3: true
} 

export const homeObjFour = {
    id: 'blog',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'A Blog by Rose Shanahan',
    headline: 'Acceptance Guaranteed?',
    description: 'It is important to "size it right". A blog for parents and professionals.',
    imgStart: true,
    scrollId: '#post',
    buttonLabel: 'Scroll down',
    img: require('../../images/svg-6.svg').default,
    alt: 'Pacifier',
    dark: true,
    primary: true,
    darkText: false,
    appear: false,
    appear2: false,
    iframeDisplay: false,
    imgDisplay: true
}

export const homeObjFive = {
    id: 'termsofservice',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Pacified®',
    headline: 'Terms of Service',
    description: 'Some legal stuff.',
    imgStart: false,
    scrollId: '#post',
    buttonLabel: 'Scroll down',
    img: require('../../images/svg-7.svg').default,
    alt: 'Pacifier',
    dark: false,
    primary: false,
    darkText: true,
    appear: false,
    appear2: false,
    iframeDisplay: false,
    imgDisplay: true
}

export const homeObjSix = {
    id: 'privacypolicy',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Pacified®',
    headline: 'Privacy Policy',
    description: "That's right. We value your privacy.",
    imgStart: true,
    scrollId: '#post',
    buttonLabel: 'Scroll down',
    img: require('../../images/svg-8.svg').default,
    alt: 'Pacifier',
    dark: false,
    primary: false,
    darkText: true,
    appear: false,
    appear2: false,
    iframeDisplay: false,
    imgDisplay: true
}

export const homeObjSeven = {
    id: 'disclaimer',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Pacified®',
    headline: 'Sizing Guidelines',
    description: "Why sizing matters...",
    imgStart: false,
    scrollId: '#post',
    buttonLabel: 'Scroll down',
    img: require('../../images/svg-12.svg').default,
    alt: 'Pacifier',
    dark: false,
    primary: false,
    darkText: true,
    appear: false,
    appear2: false,
    iframeDisplay: false,
    imgDisplay: true
}

export const homeObjEight = {
    id: 'contactus',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Pacified®',
    headline: 'Contact Us',
    description: "We'd love to hear from you.",
    imgStart: false,
    scrollId: '#post',
    buttonLabel: 'Scroll down',
    img: require('../../images/svg-10.svg').default,
    alt: 'Pacifier',
    dark: false,
    primary: false,
    darkText: true,
    appear: false,
    appear2: false,
    iframeDisplay: true,
    srcLink: "https://docs.google.com/forms/d/e/1FAIpQLSdHqk5r5glUbv4ScEsxt62wKp1NZTXbv43WO-fo0HkKDvPuvg/viewform?embedded=true",
    imgDisplay: false
}

export const homeObjNine = {
    id: 'blog',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'By David A.Tesini DMD MS',
    headline: 'Science behind Pacified®',
    description: 'What actually makes a pacifier baby-friendly?',
    imgStart: false,
    scrollId: '#post',
    buttonLabel: 'Scroll down',
    img: require('../../images/svg-11.svg').default,
    alt: 'Pacifier',
    dark: true,
    primary: true,
    darkText: false,
    appear: false,
    appear2: false,
    iframeDisplay: false,
    imgDisplay: true
}

export const homeObjTen = {
    id: 'chat',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Pacified®',
    headline: 'Chat with Us',
    description: 'Connect with us for collaborative or licensing opportunities',
    imgStart: false,
    scrollId: '#post',
    buttonLabel: 'Scroll down',
    img: require('../../images/svg-11.svg').default,
    alt: 'Pacifier',
    dark: false,
    primary: false,
    darkText: true,
    appear: false,
    appear2: false,
    iframeDisplay: true,
    srcLink: "https://links.collect.chat/61148ef336b5b233b034898e",
    imgDisplay: false
}

export const homeObjEleven = {
    id: 'aboutus',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Pacified®',
    headline: 'About the app',
    description: 'A brief overview of what the app is all about',
    imgStart: false,
    scrollId: '#about',
    buttonLabel: 'Scroll down',
    img: require('../../images/svg-11.svg').default,
    alt: 'Pacifier',
    dark: true,
    primary: true,
    darkText: false,
    appear: false,
    appear2: false,
    iframeDisplay: false,
    srcLink: "https://links.collect.chat/61148ef336b5b233b034898e",
    imgDisplay: true
}