import React, { useState } from 'react';
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import InfoSection from "../components/InfoSection";
import { homeObjFive } from '../components/InfoSection/Data';
import AboutUs from '../components/AboutUs';
import { terms } from '../components/AboutUs/data';

const TermsOfService = () => {
    
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} type={false}/>
            <Navbar toggle={toggle} margin={true} type={false}/>
            <InfoSection {...homeObjFive}/>
            <AboutUs {...terms}/>
            <Footer/>
        </>
    )
}

export default TermsOfService;
