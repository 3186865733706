import React, { useState } from 'react';
import Footer from '../components/Footer';
import InfoSection from "../components/InfoSection";
import { homeObjTen } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar';
import Sidebar from "../components/Sidebar";

const Contact = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} type={false}/>
            <Navbar/>
            <InfoSection {...homeObjTen}/>
            <Footer/>
        </>
    )
}

export default Contact;
