import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import Icon1 from '../../images/svg-1.svg';
import Icon2 from '../../images/svg-2.svg';
import Icon3 from '../../images/svg-3.svg';
import Icon4 from '../../images/svg-4.svg';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP, ServicesH3 } from './ServicesElements';

const Services = () => {
    
    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesH3>Tap on the cards to learn more</ServicesH3>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Gnathic-Click</ServicesH2>
                    <ServicesP>The Gnathic-Click smartphone application allows cross discipline evaluation of cranio-facial growth. </ServicesP>
                </ServicesCard>
                <ServicesCard to="/chat-with-us" onClick={toggleHome}>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Collaborations</ServicesH2>
                    <ServicesP>For over 37 years Toothprints PC has collaborated with
multinational companies and our academic affiliates.</ServicesP>
                </ServicesCard>
                <ServicesCard to="/chat-with-us" onClick={toggleHome}>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Licensing</ServicesH2>
                    <ServicesP>Products categorized similar to the PACIFIED® smartphone platform, are now available to the large retailer market</ServicesP>
                </ServicesCard>
                <ServicesCard to={{pathname:"https://toothprints.info/"}} target="_blank">
                    <ServicesIcon src={Icon4} />
                    <ServicesH2>Toothprints PC</ServicesH2>
                    <ServicesP>Take a look at our parent company and what all we offer. Revolutionizing dental technology, one step at a time.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
};

export default Services;
