import React, { useState } from 'react';
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import InfoSection from "../components/InfoSection";
import { homeObjEight } from '../components/InfoSection/Data';


const ContactUs = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} type={false}/>
            <Navbar toggle={toggle} margin={true} type={false}/>
            <InfoSection {...homeObjEight}/>
            <Footer/>
        </>
    )
}

export default ContactUs;
