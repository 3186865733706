import React from 'react';
import { BodyText, TextContainer, Box, Iframe, Img, Img2 } from './AboutUsElements';
// import pdf from '../../pdfs/privacypolicy.pdf';
import Image from '../../images/pacifier-palate-graph.png';

const AboutUs = ({ Content, Content2, Content3, iframedisplay, iframecontent, imgDisplay, image2, imgDisplay2, topMargin, topMargin2}) => {
    return (
        <>
            <BodyText id="post">
                <TextContainer>
                    <Box>
                        {Content}<br></br><br></br>
                        {Content2}<br></br><br></br>
                        <Img src={ Image } imgDisplay={imgDisplay}></Img>
                        {Content3}
                        <Iframe src={iframecontent + "#toolbar=0"} iframedisplay={iframedisplay}/>
                        <Img2 src={ image2 } imgDisplay={imgDisplay2} topMargin={topMargin} topMargin2={topMargin2}></Img2>
                    </Box>
                </TextContainer>
            </BodyText>
        </>
    )
}

export default AboutUs;
