import pdf1 from '../../pdfs/privacypolicy.pdf'; 
import pdf2 from '../../pdfs/pacifieddescription.png'; 
import pdf3 from '../../pdfs/sizeguides2.png';


export const terms = {
    Content: `hmnkbkbkkji`,
    iframedisplay: false
};

export const privacy = {
    Content: ``,
    iframecontent: pdf1,
    iframedisplay: true,
    imgDisplay: false,
}

export const sizing_guidelines = {
    Content: `The lack of any standardization (within brand and across brand)  left us with no reliable starting point for a biometric sizing/fit standard. The variations from brand to brand based on chronological age were unable to be placed in any matrix.Our objective was clearly only to improve the chances that mothers would be purchasing proper sized pacifiers to support the palate during NNS. To this end major brand pacifiers were measured with an electronic digital caliper for all chronological ages. Palatal width measurements were obtained from dental impressions from varying sources and scanned with a 3 Shape R700 Orthodontic Scanner to generate STL files.In some cases IGES files provided by manufacturer were used to generate STL files of the pacifier by using Autodesk Fusion 360™ software. The STL file of each pacifier stage was then imported into Autodesk Meshmixer™ to obtain "observational fit" for contact with the tectal plate area of the palate.
    Further cross reference was made with an applied "working formula". The formula was computed by plotting the 'best-fit' polynomial over points, constructed as (palate width, pacifier width), between 6 infants with ages ranging from 1 month to 24 months.`,
    Content2: `Equation 1: Palate width = 0.33 * (Pacifier Width) + 22.52 | Equation 2: Palate width = 19.4 + 0.48 * (Pacifier Width) + 0.01 * (Pacifier Width)²`,
    Content3: `The blue line denotes the linear graph while the orange line denotes the polynomial graph. Both of these graphs have been constructed by using the pre-mentioned data points. Further, consideration was given to manufacturers' sizing of the bulb (which varied from 12.8 mm to 25 mm) and the brand's placement of chronological age recommendation in the product SKU. 
    Recently a computational simulation FEA model was developed to evaluate the dynamic contact of a pacifier with the palate (Lee et al 2021)*This model can validate brand pacifier sizing in infant palates. This approach will allow brands  to develop develop a biometric sizing matrix (in preparation for publication)
    The numerous variations from one brand to another were considered and "BIOMETRIC SIZING" was defined by treating this available ordinal and descriptive data in groupings reflecting a pilot attempt at a sizing/fit standard. Chronological Age sizing ranges will be replaced with Biometric bulb width sizing ranges.`,
    iframedisplay: false,
    imgDisplay: true,
    imgDisplay2: true,
    image2: pdf3
}

export const about = {
    Content:'',
    iframecontent: pdf2,
    iframedisplay: false,
    imgDisplay: false,
    image2: pdf2,
    imgDisplay2: true,
    topMargin: true,
    topMargin2: true
}

export const sizingpaper = {
    Content:'',
    iframecontent: pdf3,
    iframedisplay: false,
    imgDisplay: false
}