import React, { useState, useEffect } from 'react';
import {FaBars} from 'react-icons/fa';
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, Imgs, StoreItem} from './NavbarElements';
// import { Player, Controls } from '@lottiefiles/react-lottie-player';
// import image from '../../images/logo.svg'
import { animateScroll as scroll } from 'react-scroll';
import imgs from '../../images/PacifiedLogo_White.png';
import imgsDark from '../../images/paci-logo.png';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import gplaystore from '../../images/google-play-badge.png';
import applestore from '../../images/downloadonappstore.png';

const Navbar = ({ toggle, margin, type }) => {

    const[scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true);
        }
        else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <Nav scrollNav={scrollNav} margin={margin}>
                <NavbarContainer>
                {/* <Player autoplay loop src="https://assets3.lottiefiles.com/packages/lf20_UJNc2t.json" style={{ height: '100px', width: '100px' }}> */}
                    <NavLogo to="/" onClick={toggleHome}><Imgs src={(scrollNav) ? (imgsDark) : (imgs)}></Imgs></NavLogo>
                    
                        {/* <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                    </Player> */}
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu type={type}>
                        <NavItem>
                            <NavLinks to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80}>About</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="discover" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Discover</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="services" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Services</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="contact" smooth={true} duration={500} spy={true} exact='true' offset={-80}>Contact</NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                    <Popup trigger={<NavBtnLink to="" scrollNav={scrollNav}>Download Pacified® App</NavBtnLink>}modal nested>{close => (
                            <div className="modal">
                                <button className="close" onClick={close}>
                                &times;
                                </button>
                                <div class="content">
                                <StoreItem to={{pathname: "https://apps.apple.com/us/app/pacified/id1528971525"}} target="_blank">
                                    <Imgs src={applestore}></Imgs>
                                </StoreItem>
                                <StoreItem to={{pathname: "https://play.google.com/store/apps/details?id=info.toothprint"}} target="_blank">
                                    <Imgs src={gplaystore}></Imgs>
                                </StoreItem>
                                </div>
                                {/* <div className="header"> Modal Title </div>
                                <div className="content">
                                {' '}
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque, a nostrum.
                                Dolorem, repellat quidem ut, minima sint vel eveniet quibusdam voluptates
                                delectus doloremque, explicabo tempore dicta adipisci fugit amet dignissimos?
                                <br />
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur sit
                                commodi beatae optio voluptatum sed eius cumque, delectus saepe repudiandae
                                explicabo nemo nam libero ad, doloribus, voluptas rem alias. Vitae?
                                </div> */}
                            </div>
                            )}
                        </Popup>
                        {/* <NavBtnLink to={{ pathname: "https://apps.apple.com/us/app/uno/id1344700142"}} target="_blank" scrollNav={scrollNav}>Download Pacified® App</NavBtnLink> */}
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </>
    );
}

export default Navbar;
